import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import Layout from "../../components/layouts/layout";
import Image from "../../components/widgets/basico/image";
import TabsModelosCarrousel from "../../components/widgets/TabsModelosCarrousel";

export default function GraciasPorContactarnos(props){

    const parameters = props.location.search;

    return (
        <Layout parameters={parameters}>
            <br />
            <Container>
                <div className="bg-white">
                    <br />
                    <div className="px-3">
                        <Image fileName="gracias-por-contactarnos.jpg" alt="Grupo Rivero 404" />
                    </div>
                    <Container>
                        <Col className="mt-4 px-3">
                            <Row>
                                <TabsModelosCarrousel props={props}/>
                            </Row>
                        </Col>
                    </Container>
                </div>
            </Container>
        </Layout>
    )
}